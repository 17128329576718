<script setup lang="ts">
import type TnwebPage from "~/server/usecases/content/definitions/TnwebPage";
import { useStore } from "vuex";
import { usePageStore } from "~/pinia/page";

import layouts from "~/gizmo-layouts";
import telenorid from "~/telenorid/telenorid";
import GizmoFace from "~/components/platform/inline-editing/GizmoLink/GizmoFace.vue";

import abTest from "@telenornorgeinternal/tn-gizmo-abtest-plugin";
import capitalize from "~/helpers/formatting/capitalize";

const store = useStore();
const pageStore = usePageStore();

definePageMeta({
	name: "CMS-managed page",
	middleware: [
		"resolve-segment-middleware",
		"get-globals-middleware",
		(to) => {
			// No explicit index pages (#363)
			if (to.path.endsWith("index.page")) {
				return navigateTo(
					{
						path: to.path.replace("index.page", ""),
						query: to.query,
					},
					{ redirectCode: 301 },
				);
			}

			if (to.path.endsWith(".jsp")) {
				return navigateTo(
					{
						path: to.path.replace(".jsp", ".page"),
						query: to.query,
					},
					{ redirectCode: 301 },
				);
			}

			// If the URL ends without a slash or suffix, try to add a slash
			if (!to.path.endsWith("/") && !/.+\..+$/.test(to.path)) {
				return navigateTo({
					path: to.path + "/",
					query: to.query,
				});
			}
		},
	],
});

const route = useRoute();

const {
	data: loadedPage,
	error,
	status,
} = await useFetch<TnwebPage>(`/api/content/page/${encodeURIComponent(route.path)}`, {
	deep: false,
});

if (!loadedPage.value) {
	console.error(`Page not found: ${route.path}. Status: ${status.value}`, error.value);
	throw createError({ statusCode: 404, message: "Page not found", fatal: true });
}

let redirectUrl = loadedPage.value?.metadata?.url;
if (redirectUrl) {
	if (Object.keys(route.query).length > 0) {
		if (redirectUrl.includes("?")) {
			redirectUrl += "&";
		} else {
			redirectUrl += "?";
		}
		redirectUrl += new URLSearchParams(route.query).toString();
	}
	await navigateTo(redirectUrl, { external: true, redirectCode: 301 });
}

let page = loadedPage.value;

const abTestPage = abTest.getAbTestPage(page, useRoute()?.query?.ab);

if (abTestPage?.gizmoAbTest?.testIndex > 0 && !useRoute()?.query?.ab) {
	await navigateTo({ query: { ab: abTestPage?.gizmoAbTest?.testIndex, ...useRoute().query } }, { external: true });
} else {
	page = abTestPage;
}

await pageStore.setPage(page);

let layoutRef = page?.template?.ref || "default";

if (layoutRef.indexOf("/")) layoutRef = layoutRef.split("/").pop();

if (layoutRef === "article") {
	layoutRef = "standardArticle";
} else if (layoutRef === "yngStripped") {
	layoutRef = "stripped";
}

const layout = layouts[layoutRef];

const shareTitle =
	store.state.ecommerce?.product?.title ||
	page?.metadata?.shareTitle ||
	page?.metadata?.title ||
	capitalize(page?.metadata?.pageAction, true);
const shareDescription =
	store.state?.ecommerce?.product?.descriptionSummary ||
	page?.metadata?.shareDescription ||
	page?.metadata?.description;
const shareImage =
	(page?.metadata?.shareImage
		? `https://www.telenor.no${page?.metadata?.shareImage?.src}`
		: page?.metadata?.articleImage
			? `https://www.telenor.no${page?.metadata?.articleImage?.image_md?.src}`
			: `https://www.telenor.no/assets/share-fallback-optimized.png`
	).replace(/\?.+$/, "") + "?mimeType=original"; // Ensure LinkedIn compatibility
const canonicalUrl =
	page?.metadata?.canonical?.url || (route.path && `https://www.telenor.no${route.path.replace("index.page", "")}`);

useSeoMeta({
	title: shareTitle,
});

useServerSeoMeta({
	ogUrl: canonicalUrl,
	ogType: "website",
	title: shareTitle,
	ogTitle: shareTitle,
	twitterTitle: shareTitle,
	description: shareDescription,
	ogDescription: shareDescription,
	twitterDescription: shareDescription,
	ogImage: shareImage,
	twitterImage: shareImage,
	twitterCard: "summary_large_image",
	twitterSite: "@TelenorNorge",
	robots: page?.metadata?.standardMeta?.metatags?.join(", "),
});

const linkTags = [
	{
		rel: "canonical",
		href: canonicalUrl,
	},
];

if (page?.metadata?.useFaviconTwe) {
	linkTags.push(
		{ rel: "icon", href: "/favicon/favicon-twe.ico", sizes: "16x16" },
		{ rel: "icon", href: "/favicon/favicon.svg", type: "image/svg+xml" },
		{ rel: "apple-touch-icon", href: "/favicon/apple-touch-icon-twe.png" },
	);
}

useHead({
	link: linkTags.filter((tag) => !!tag.href),
});

if (!store.state.attemptedLogin) {
	onMounted(async () => {
		const user = await telenorid.getOrLoginUser();
		if (user) await store.dispatch("setUser", user);

		store.commit("setAttemptedLogin", true);
	});
}

const HERO_COMPONENTS = [
	"hero-banner--normal",
	"hero-banner--video",
	"gateway-links-bar",
	"hero-banner--video-twe",
	"vev-component-no-spacing",
];

const handlePageColor = () => {
	let pageColor = "white";
	if (page?.metadata?.dark) {
		pageColor = "dark";
	} else if (
		[
			["/privat/internett/bestill/"].includes(useRoute().path),
			useRoute().path.startsWith("/mitt-telenor"),
			useRoute().path.includes("/forsikring"),
		].some((e) => e)
	) {
		pageColor = "gray";
	}

	onMounted(() => {
		if (page?.metadata?.dark) {
			document.body.classList.add("dark-background");
		} else {
			document.body.classList.remove("dark-background");
		}
	});
	return pageColor;
};
const pageColor = handlePageColor();

const sections = page?.layouts;
const segmentedSections = sections?.filter(useComponentSegmentation);

const breadcrumbsBelowHero = computed(() => {
	return HERO_COMPONENTS.includes(sections?.[0]?.columns?.[0]?.[0]?.template?.name);
});

const { $sendPageData } = useNuxtApp();

useChat();

onMounted(() => {
	$sendPageData(useRoute());
});
</script>

<template>
	<NuxtLayout>
		<div :class="['layoutOuterContainer dynamic-page', `bg-${pageColor}`]">
			<component
				:is="layout"
				v-if="layout"
			>
				<div :class="{ 'color-theme--dark': sections?.[0]?.background === 'dark' }">
					<div
						class="content-container"
						v-if="!breadcrumbsBelowHero"
					>
						<LazyBreadcrumbs
							:dark="sections?.[0]?.background === 'dark' || pageColor === 'dark'"
							:metadata="page?.metadata"
							:page="page"
							:layouts="sections"
						/>
					</div>
				</div>
				<template
					v-for="(section, index) in segmentedSections"
					:key="`${section}-${index}`"
				>
					<LazyLayoutDropzone
						:key="`dropzone-${index}`"
						:layout-idx="index"
						v-if="$config.public.isPreview"
					/>
					<TSection
						:layout="section"
						:index="index"
						:page-color="pageColor"
					/>
				</template>

				<div
					class="content-container"
					v-if="page?.metadata?.coreLinks?.length > 0"
				>
					<LazyCoreLinks
						:links="page?.metadata.coreLinks"
						class="margin-bottom-xl"
					/>
				</div>
			</component>
		</div>
		<div
			id="tn-chat"
			class=""
			data-auto-open="false"
			data-auto-start="false"
		></div>
		<div
			class="developer-toolbar"
			v-if="!useNuxtApp().$config.public.DISABLE_DEVTOOLS && !store?.state?.isMobile"
		>
			<GizmoFace />
		</div>
	</NuxtLayout>
</template>

<style scoped lang="scss">
.layoutOuterContainer {
	min-height: 100vh;
}

.developer-toolbar {
	position: fixed;
	bottom: $spacing-m;
	right: $spacing-m;
	z-index: 10000;
	display: flex;
}
</style>
